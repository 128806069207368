import React, { Component } from "react";

class Job extends Component {
  render() {
    const { details, colors } = this.props;
    return (
      <div
        className="job__container"
        style={{ background: colors }}
      >
        <div className="job">
          <a
            href={details.website}
            rel="noopener noreferrer"
            className="job__btn"
            target="_blank"
          >
            {details.website}
          </a>
          <div className="job__header">
            <h2>{details.position}</h2>
            <h4>{details.company}</h4>
         
          </div>

          <div className="job__date">
            {details.current
              ? `Current Job as of ${details.startDate}`
              : `${details.startDate} to ${details.endDate}`}
          </div>
          <br />

          <p className="job__paragraph">
            {details.summary}
          </p>
          <ul className="job__list">
            {details.highlights.map(
              (key, index) => {
                return (
                  <li key={index}>
                    {details.highlights[index]}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default Job;
