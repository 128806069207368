import React, { Component } from 'react';
import Jobs from './Job';

class Card extends Component {
  render() {
    const { work } = this.props.resumeObj;
    const bgColors = ['#3C787E', '#FA9500', '#0E0004', '#7C6A0A' ,'#EB6424',"#3A2D32"];
   
    return (
      <div className="card__container">
        <div className="card__content">
         {
            work.map((key, index) => {
              return (
                <Jobs
                  key={index} 
                  details={work[index]}
                  colors={bgColors[index]}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default Card;
