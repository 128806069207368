import React, { Component } from "react"

class Hero extends Component {
  render() {
    const { basics } = this.props.resumeObj

    return (
      <div className='hero'>
        <img src={basics.image} alt='Georgios profile pic' className='hero__profile' />
        <h1 className='hero__header'>{basics.name}</h1>
        <h3 className='hero__subheader'>{basics.label} </h3>
        <h4 className='hero__subheader'>{basics.location.city} </h4>

        <p>{basics.summary}</p>
        {basics.pdf ? (
          <p className='hero__text'>
            <a href={basics.pdf}>PDF version</a> of the CV.
          </p>
        ) : (
          ""
        )}

        <span role='img' aria-label='Arrow down' className='hero__arrow'>
          ⬇️
        </span>
      </div>
    )
  }
}

export default Hero
