import React, { Component } from "react"
import Project from "./Project"

class ProjectList extends Component {
  render() {
    const { projects } = this.props.resumeObj

    return (
      <div className='skills-list__container' style={{ display: "flex" }}>
        <div className='skills-list__modal'>
          <h1 className='skills-list__header'>
            Projects
            <span role='img' aria-label='laptop'>
              💻
            </span>
          </h1>
          <br />
          <div className="project-container">

            {projects.map((project,index) => (
              <Project key={`project-${index}`} project={project} />
            ))}
         
            </div>
        </div>
      </div>
    )
  }
}

export default ProjectList
