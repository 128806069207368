import React from "react"

const Education = (props) => {
  const { resumeObj } = props

  return (
    <div className='education__container' style={{ display: "flex" }}>
      <div className='education__modal'>
        <div className='education'>
          <h1 className='education__header'>Education</h1>
          {resumeObj.education.map((key, index) => {
            return (
              <div className='education__text'>
                <div className='education__subheader'>
                  <a
                    href={key.website}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='education__btn'>
                    {key.institution}
                  </a>
                </div>
                <h5>
                  {key.studyType} - {key.area}
                </h5>
                <h5>Graduated {key.endDate}</h5>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Education
