import React, { Component } from "react";

class Project extends Component {
  render() {
    return (
      <div className="project-card">
        <div className="project-card__header">
          
        <div>
          
            {this.props.project.name}
          </div>
        
        <div>
          <a
            href={this.props.project.website}
            target="_blank"
            rel="noopener noreferrer"
            className="education__btn"
          >
            {this.props.project.website}
          </a>
        </div>
        </div>
        
        <div>{this.props.project.summary}</div>
      </div>
    );
  }
}

export default Project;
